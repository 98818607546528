<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-md modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("common.doctor") }}</h5>
                </div>
                <form @submit.prevent="submitData()">
                    <div class="modal-body">
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >{{ $t("common.name") }} :</label
                            >
                            <div class="col-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="nama"
                                    placeholder="Nama Dokter"
                                    v-validate="'required'"
                                    data-vv-as="Nama Dokter"
                                    name="nama"
                                    :class="{
                                        'is-invalid': errors.has('nama')
                                    }"
                                />
                                <div
                                    v-show="errors.first('nama')"
                                    class="invalid-feedback"
                                >
                                    {{ errors.first("nama") }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >{{ $t("common.tanggalLahir") }} :</label
                            >
                            <div class="col-6">
                                <date-picker
                                    :changedValue.sync="date"
                                    :id-date-picker="'doctor_' + id"
                                    :picker-value="date"
                                    place-holder="DD/MM/YYYY"
                                    :is-validate="true"
                                    start-date=""
                                    end-date="-0d"
                                ></date-picker>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >{{ $t("common.poly") }} :</label
                            >
                            <div class="col-6">
                                <poli-select
                                    v-model="tujuanRujukan"
                                    :validation="'required'"
                                ></poli-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >{{ $t("common.tts") }} :</label
                            >
                            <div class="col-6">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="textToSpeech"
                                    placeholder="Text to Speech"
                                    name="textToSpeech"
                                />
                            </div>
                            <div class="col-1">
                                <button
                                    type="button"
                                    title="Bunyikan"
                                    class="btn btn-secondary btn-icon"
                                    @click="speakTts"
                                >
                                    <i
                                        :class="[
                                            speechState
                                                ? 'flaticon2-speaker'
                                                : 'la la-play'
                                        ]"
                                    ></i>
                                </button>
                            </div>
                        </div>
                        <div v-if="checkPermission('TarifResource.GET.Tarif')">
                            <div class="form-group row">
                                <label
                                    for="example-text-input"
                                    class="col-4 col-form-label"
                                    >Tipe Konsultasi :</label
                                >
                                <div class="col-lg-6">
                                    <vue-multi-select
                                        :options="consultationOptions"
                                        v-model="consultationType"
                                        :name="'consultationType'"
                                        :validation="'required'"
                                        :validationName="'Tipe Konsultasi'"
                                        @change="eventChange"
                                    ></vue-multi-select>
                                </div>
                            </div>
                            <div></div>
                            <div class="form-group row"
                                v-if="consultationType == 'Semua Tipe Konsultasi' ">
                                <label
                                    for="example-text-input"
                                    class="col-4 col-form-label"
                                    >Tarif Telekonsultasi :</label
                                >
                                <div class="col-lg-6">
                                    <vue-multi-select
                                        :options="dataResponse"
                                        v-model="selectedTarif"
                                        :name="'TarifTelekonsultasi'"
                                        :validation="'required'"
                                        :custom-label="nameWithPrice"
                                        :validationName="'Tarif Telekonsultasi'"
                                        @change="eventChange"
                                    ></vue-multi-select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-4"></div>
                            <div class="kt-checkbox-inline col-lg-6 px-3">
                                <label class="kt-checkbox">
                                    <input
                                        type="checkbox"
                                        v-model="isPublished"
                                    />
                                    Publish
                                    <span></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="clearData()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "../../_general/DatePicker";
import PoliSelect from "../../_select/Poli.vue";
import SweetAlertTemplate from "../../_general/SweetAlert";
import JadwalDay from "../../_select/JadwalDay";
import JadwalTime from "../../_select/JadwalTime";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import { isNullOrUndefined } from "util";
import Speech from "speak-tts";
import CheckPermission from "./../../../services/checkPermission.service";
import vueMultiSelect from "../../_select/VueMultiSelect";
const checkPermission = new CheckPermission()
const speech = new Speech();
const DoctorRepository = RepositoryFactory.get("doctor");
const SweetAlert = Vue.extend(SweetAlertTemplate);

export default {
    components: {
        PoliSelect,
        DatePicker,
        JadwalDay,
        JadwalTime,
        vueMultiSelect
    },
    data() {
        return {
            id: null,
            nama: "",
            date: "",
            tujuanRujukan: null,
            textToSpeech: null,
            listSchedule: [],
            jadwals: [],
            isPublished: true,
            speechState: false,
            consultationOptions: [
                "Semua Tipe Konsultasi",
                "Hanya Konsultasi Langsung"
            ],
            consultationType: null,
            dataResponse: [
                // {
                //     createdBy: "admin",
                //     createdDate: "2021-04-01T06:21:27.772Z",
                //     createdDateTime: "2021-04-01T13:21:27.772",
                //     deletedAt: null,
                //     deskripsi: "telmed 1",
                //     id: 2751,
                //     jumlah: 200000,
                //     lastModifiedBy: "admin",
                //     lastModifiedDate: "2021-04-01T06:21:27.772Z",
                //     lastModifiedDateTime: "2021-04-01T13:21:27.772",
                //     nama: "tarif_telmed",
                // }
            ],
            selectedTarif: null,
            haveTarifAccess: false
        };
    },
    props: {
        modalName: { type: String, default: "modal_doctor" },
        modalData: {}
    },
    provide() {
        return {
            $validator: this.$validator
        };
    },
    methods: {
        speakTts: function() {
            var vx = this;
            speech.init({
                volume: 1,
                lang: "id-ID",
                rate: 0.5,
                pitch: 2,
                voice: "Google Bahasa Indonesia",
                splitSentences: true,
                listeners: {
                    onvoiceschanged: voices => {
                        console.log("Event voiceschanged", voices);
                    }
                }
            });

            speech
                .init()
                .then(data => {
                    speech
                        .speak({
                            text:
                                "Antrian:" +
                                vx.textToSpeech +
                                ", 0 0 3, ke, counter 1",
                            listeners: {
                                onstart: () => {
                                    vx.speechState = true;
                                },
                                onend: () => {
                                    vx.speechState = false;
                                },
                                onresume: () => {
                                    vx.speechState = true;
                                }
                            }
                        })
                        .then(() => {})
                        .catch(e => {});
                })
                .catch(e => {});
        },
        clearData: function() {
            $("#" + this.modalName).modal("hide");
            this.$emit("update:showModal", false);
        },
        updateData: function(doctor) {
            this.id = doctor.id;
            this.nama = doctor.nama;
            this.date = doctor.tanggalLahir;
            this.textToSpeech = doctor.textToSpeech;
            this.jadwals = doctor.jadwals;
            this.isPublished = isNullOrUndefined(doctor.isPublished)
                ? false
                : doctor.isPublished;
            this.tujuanRujukan = doctor.tujuanRujukan
                ? doctor.tujuanRujukan
            : null;
            // if (this.isPublished )
            this.consultationType = this.consultationOptions[0];
            if(!doctor.tarif)
                this.consultationType = this.consultationOptions[1];
            else 
                this.selectedTarif = doctor.tarif
            var arrJadwal = [];

            var loop = 0;

            $.each(doctor.jadwals, function(index, value) {
                $.each(value.jam, function(index, jam) {
                    if (jam.hari == value.hari) {
                        var objJadwal = {
                            id: loop++,
                            id_jadwal: jam.id,
                            day_of_week: jam.hari,
                            timepicker_start: jam.jamMulai,
                            timepicker_finish: jam.jamSelesai
                        };
                        arrJadwal.push(objJadwal);
                    }
                });
            });

            this.jadwalData = arrJadwal;
        },
        submitData: function() {
            var vx = this;

            this.$validator.validateAll().then(result => {
                if (result) {
                    if (this.id) {
                        var params = {
                            id: this.id,
                            nama: this.nama,
                            tanggalLahir: this.date,
                            jadwals: this.jadwals,
                            tujuanRujukan: this.tujuanRujukan,
                            textToSpeech: this.textToSpeech,
                            isPublished: this.isPublished,
                            ...(this.selectedTarif && (this.consultationType == 'Semua Tipe Konsultasi' ) && { tarif : {id :this.selectedTarif.id}}  )
                            // tarif : {
                            //     id : this.selectedTarif ? this.selectedTarif.id : null  
                            // }
                        };
                        

                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data Dokter akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.update(params);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        var params = {
                            nama: this.nama,
                            tanggalLahir: this.date,
                            jadwals: this.jadwals,
                            tujuanRujukan: this.tujuanRujukan,
                            textToSpeech: this.textToSpeech,
                            isPublished: this.isPublished,
                        };

                        if(vx.selectedTarif) {
                            params.tarif = {
                                id: this.selectedTarif.id
                            }
                        }else{
                            params.tarif = null
                        }

                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data Dokter baru akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.create(params);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }
                }
            });
        },
        async update(payload) {
            var vx = this;
            await DoctorRepository.updateDoctor(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.$emit("event", resolve);
                        resolve()
                    }).then(response => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText:
                                "Perubahan data Dokter berhasil disimpan.",
                            swalType: "success",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                                vx.clearData();
                                vx.$emit("event");
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Perubahan data Dokter gagal disimpan.",
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        async create(payload) {
            var vx = this;

            await DoctorRepository.createDoctor(payload)
                .then(response => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Dokter baru berhasil ditambahkan.",
                        swalType: "success",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                            vx.clearData();
                            vx.$emit("event");
                        }
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data Dokter baru gagal ditambahkan.",
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        eventChange(val) {
            // console.log(val);
        },
        getTarif(){
            new Promise((resolve, reject) => {
                DoctorRepository.getAllTarif({}, resolve, reject);
            }).then(({ data }) => { 
                this.dataResponse = data;
            }).catch(error => {toastr.error('Tarif: error');})
        },
        checkPermission: function (method) {
            const havePermission = checkPermission.isCanAccess(method); 
            return havePermission
        },
        nameWithPrice({nama, jumlah}){
            return `${nama} - ${jumlah}` 
        }
    },
    mounted() {
        $("#" + this.modalName).modal();
        if (this.modalData) {
            this.updateData(this.modalData);
        }
            // console.log(this.checkPermission('TarifResource.GET.Tarif'));

        if (this.checkPermission('TarifResource.GET.Tarif')) {
            this.getTarif()
            // console.log("dapet");
        }
    }
};
</script>
